import React from 'react'
import PropTypes from 'prop-types'

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <div>
      <ul className="icons">
        <li>
          <a
            href="https://github.com/jwhy89"
            className="icon fa-github"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/jsyg1725/"
            className="icon fa-linkedin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
      </ul>
    </div>
    <div className="copyright">
      &copy; Dimension. Design: <a href="https://html5up.net">HTML5 UP</a>.
      Built with: <a href="https://www.gatsbyjs.org/">Gatsby.js</a>
    </div>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
