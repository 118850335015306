import React from 'react'
import PropTypes from 'prop-types'
import Icons from './Icons'

import pic01 from '../images/pic01.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            I began my full stack software engineering journey in 2018 at{' '}
            <a
              href="https://primeacademy.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Prime Digital Academy
            </a>
            . In my previous career, I was a Packaging Engineer Specialist
            involved in food, industrial, and medical device{' '}
            <a
              href="https://en.wikipedia.org/wiki/Packaging_and_labeling"
              target="_blank"
              rel="noopener noreferrer"
            >
              packaging
            </a>
            . I was fortunate to move into the DevOps space and have found my
            new calling.
          </p>

          <p>
            The transition to DevOps has been fantastic and where I feel most
            excited and motivated. As I continue to immerse myself in DevOps, I
            want to learn DevOps best practices, engage with the community, and
            give back by volunteering and mentoring.
          </p>

          <p>
            My 2024 New Year's resolutions are to lose those 20 lbs from last year,
            pass a certification exam, and help host a meetup!
          </p>

          <p>
            DevOps and Kubernetes Poem by Claude 3!
          </p>

          <p>
            There once was a team, DevOps by name,<br />
            Whose mission was keeping their systems tame.<br />
            With Kubernetes at hand, they ruled the land,<br />
            Deploying and scaling with nary a jam.<br />
            <br />
            They'd spin up containers like chefs making soup,<br />
            Their microservices formed an elite troop.<br />
            With rollouts and rollbacks, they danced with such grace,<br />
            While monitoring logs just in case.<br />
            <br />
            The DevOps folks laughed at the old monolithic ways,<br />
            "We're cloud-native now, keep up with the craze!"<br />
            They'd orchestrate workloads with Kubernetes' might,<br />
            And high-five each other when it all worked out right.<br />
            <br />
            But when something went wrong, and an app fell apart,<br />
            They'd scramble and troubleshoot with all of their heart.<br />
            "The pods are all restless, the nodes are dismayed!"<br />
            They'd cry out in anguish, though never dismayed.<br />
            <br />
            Through all the chaos, they'd persevere,<br />
            Embracing the challenges with nary a fear.<br />
            For DevOps and Kubernetes, a match made in tech heaven,<br />
            Kept their systems running, like a well-oiled leaven.
          </p>
          {close}
        </article>

        <article
          id="devops"
          className={`${this.props.article === 'devops' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">What is DevOps?</h2>

          <p>
            DevOps is a software development methodology that emphasizes
            collaboration, communication, and integration between software
            developers and IT operations professionals. The goal of DevOps is to
            enable organizations to deliver software and services faster and
            more reliably, by automating and streamlining the software
            development and deployment process.
          </p>

          <p>
            DevOps involves a set of practices and tools that enable teams to
            automate and monitor the entire software development and deployment
            process, from code creation and testing, to deployment and
            operation. This allows teams to quickly and consistently deliver
            high-quality software and services to customers.
          </p>

          <p>
            Overall, DevOps is a powerful approach that can help organizations
            deliver software and services faster, with higher quality, and with
            fewer problems.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Me</h2>
          <p>
            Technologies I have worked with: AWS, Azure, Git, GitLab, Bitbucket,
            GitHub, Docker, Terraform, Linux, TypeScript, Node.js, Express,
            Postgres, Python, VS Code, MySql, MSSQL, Jira, JavaScript, HTML,
            CSS, React, Redux
          </p>
          <p>
            Cool events I have been to: DevOpsDays Minneapolis, JavaScript MN
            Meetup, React MPLS Meetup, Mpls Jr Devs, Minnebar14, Minnebar16
          </p>
          <p>
            I enjoy playing volleyball and being around my family and friends.
            On my free time, I like to listen to audiobooks and podcasts. My
            other interests include fishing, new tech, and tidying.
          </p>
          <span>
            <Icons />
            <br />
          </span>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            method="post"
            action="#"
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
          </form>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main